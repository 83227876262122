import React from "react";
import "./dashboard.css";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TableauReport from "./report/TableauReport";

const Dashboard = ({ customProps }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const reports = [
    {
      name: "Health Summary + Fill Alert",
      component: TableauReport,
      props: {
        url: "https://prod-useast-a.online.tableau.com/#/site/711/views/FillAlert/HealthDashboard?:iid=1",

      }
    },
    {
      name: 'Price Update Life Cycle',
      component: TableauReport,
      props: {
        url: "https://prod-useast-a.online.tableau.com/#/site/711/views/PriceUpdateCycle/PriceUpdateLifeCycle?:iid=1",
      }
    },
    {
      name: "Transactions Dashboard",
      component: TableauReport,
      props: {
        url: "https://prod-useast-a.online.tableau.com/#/site/711/views/Transactions/TransactionDashboard",

      }
    },
    {
      name: "Price Lock Metrics",
      component: TableauReport,
      props: {
        url: "https://prod-useast-a.online.tableau.com/#/site/711/views/PriceLockAlerts/PriceLockOperationalMetricsDashboard?:iid=1",

      }
    },
  ];

  const Header = (props) => {
    return (
        <div className="visops-health-button">
          <i
              onClick={customProps.compRefClose}
              className="fas fa-2x fa-times-circle"
              title="Close the Panel"
              style={{ color: "green" }}
          />
        </div>
    );
  };

  return (
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={value} onChange={handleChange}>
            {reports.map((report, i) => (
                <Tab key={i} label={report.name} />
            ))}
          </Tabs>
          <Header />
        </Box>
        {reports.map((report, i) => {
          const { component: ReportComponent, props } = report;
          if (value === i) {
            return (
                <ReportComponent
                    key={`report-${i}`}
                    value={value}
                    index={i}
                    {...props}
                />
            );
          } else {
            return null;
          }
        })}
      </Box>
  );
};

export default Dashboard;
