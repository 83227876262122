import React from "react";
import "./TableauReport.css";
import Box from "@mui/material/Box";
import { TableauEmbed } from "@stoddabr/react-tableau-embed-live";

const TableauReport = (props) => {
  const { value, index, url, ...other } = props;
  return (
      <div hidden={value !== index} id={`simple-tabpanel-${index}`} {...other}>
        {value === index && (
            <Box sx={{ p: 3 }}>
              <TableauEmbed sourceUrl={url} />
            </Box>
        )}
      </div>
  );
};

export default TableauReport;
